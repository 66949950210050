import gql from "graphql-tag"

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, where:{status:$status, department:$department, publish:true}) {
        id
        display_address
        search_type
        status
        slug
        title
        department
        price
        size
        images
        imagetransforms
        bathroom
        bedroom
        reception
        bathroom
        description
    }

}`


/* ========== Property Area Guide ======== */
const AreaGuideDetail = gql`
query AreaGuideDetail($title: String!) {
  areaguides (where:{Title:$title}) {
      id
      URL
      Title
      Banner_Description
      Tile_Image {
        url
      }
      About_Areaguide {
        Image_Tile {
          url
        }
        About_Area_Heading
        Developer
        Release_Date
        Area_Mapping_Name      
        Amenities
      }
  }
}`

/* ========== Negotitator ======== */
const TeamDetail = gql`
query TeamDetail($email: String!) {
  teams (where:{Email:$email}) {
        id 
        Name
        Image {
          url
          alternativeText
        }
        Designation
        URL
        languages
        Phone
        Email
        imagetransforms
  }
}`
/* ========== TeamContact ======== */
const TeamContact = gql`
query TeamContact($id: String!) {
  teams (where:{id:$id}) {
        id 
        Name
        Image {
          url
          alternativeText
        }
        Designation
        URL
        languages
        Phone
        Email
        imagetransforms
  }
}`
/* ========== Property Detail For Booking ======== */

const GetPropertyBooking = gql`
query GetPropertyOffice($id: String!) {
  properties (where:{id:$id}) {
    id
    slug
    address
    display_address
    search_type
    department
    negotiator_details
    title
    images
    bedroom
    bathroom
    office_crm_id
    price
  }
}`

/* ========== JobList ======== */

const JobList = gql`
query JobList {
  careers {
    Job_Opening
  }
}`
/* ========== Property Detail For Booking ======== */

const FeaturedProperties = gql`
query FeaturedProperties($id: [String!], $status:[String!], $department:[String!]) {
    properties (where:{listing_id_contains:$id, status: $status, department: $department,publish:true}) {
    id
    slug
    address
    description
    display_address
    search_type
    price_qualifier
    size
    department
    negotiator_details
    title
    images
    bedroom
    bathroom
    office_crm_id
    price
    imagetransforms
  }
}`
export const Queries = {
    NoResultProperties,
    AreaGuideDetail,
    TeamDetail,
    TeamContact,
    GetPropertyBooking,
    JobList,
    FeaturedProperties
};


