import { useQuery} from '@apollo/client';
import { Queries } from "./common_queries";


export const NoResultProperties = (status, department) => {
    return useQuery(Queries.NoResultProperties, {
        variables: {status: status, department: department}
    });
}

export const AreaGuideDetail = (title) => {
    return useQuery(Queries.AreaGuideDetail,{
        variables: {title: title}
    });
}

export const TeamDetail = (email) => {
    return useQuery(Queries.TeamDetail,{
        variables: {email: email}
    });
}

export const TeamContact = (id) => {
    return useQuery(Queries.TeamContact,{
        variables: {id: id}
    });
}
export const GetPropertyBooking = (id) => {
    return useQuery(Queries.GetPropertyBooking,{
        variables: {id: id}
    });
}
export const FeaturedProperties = (id, status, department = "residential") => {
    return useQuery(Queries.FeaturedProperties,{
        variables: {id: id, status: status, department: department}
    });
}
export const JobList = () => {
    return useQuery(Queries.JobList);
}
